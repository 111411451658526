/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #2EAD94;
	--color2:               #42B59E;
	--color3:               #414143;
	--color4:               #E0E1E3;
	--color5:               #F9F9F9;
	--color6:               #F3F3F4;
	--color7:               #9C9C9D;
	--color8:               #1F1F21;

	// TYPOGRAPHY
	// ---------------

	--text-color:           #1F1F21;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.5625rem;

	--font-family-primary:   "Inter", Helvetica, Arial, sans-serif;
	--font-family-secondary: "korolev", var(--font-family-primary);
	--font-family-headings:  var(--font-family-primary);

	--headings-color:        var(--text-color);
	--hr-color:              #E0E1E3;

}